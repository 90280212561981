"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiWrapper = void 0;
var object_service_1 = require("../object-service");
var variables_const_1 = require("../variables.const");
var agilea_api_1 = require("./agilea-api");
var athletics_api_1 = require("./athletics-api");
var lead_api_1 = require("./lead.api");
var magicline_api_1 = require("./magicline-api");
var none_api_1 = require("./none-api");
var ApiWrapper = /** @class */ (function () {
    function ApiWrapper() {
        this.leadApi = object_service_1.ObjectService.getInstance(lead_api_1.LeadApi);
        //By default we use magicline api
        this.api = object_service_1.ObjectService.getInstance(magicline_api_1.MagiclineApi);
        if (variables_const_1._settings.technologyType === 'athletics') {
            this.api = object_service_1.ObjectService.getInstance(athletics_api_1.AthleticsApi);
            console.log('Using API: ', variables_const_1._settings.technologyType);
        }
        if (variables_const_1._settings.technologyType === 'agilea') {
            this.api = object_service_1.ObjectService.getInstance(agilea_api_1.AgileaApi);
            console.log('Using API: ', variables_const_1._settings.technologyType);
        }
        if (variables_const_1._settings.technologyType === 'none') {
            this.api = object_service_1.ObjectService.getInstance(none_api_1.NoneApi);
            console.log('Using API: ', variables_const_1._settings.technologyType);
        }
    }
    ApiWrapper.prototype.createNewCustomer = function (body) {
        console.log('new customer', body);
        return this.api.createNewCustomer(body);
    };
    ApiWrapper.prototype.previewNewCustomer = function (body) {
        console.log('preview new customer', body);
        return this.api.previewNewCustomer(body);
    };
    ApiWrapper.prototype.getStudios = function () {
        return this.api.getStudios();
    };
    ApiWrapper.prototype.createLead = function (body) {
        return this.api.createLead(body);
    };
    ApiWrapper.prototype.createFirestoreLead = function (body) {
        return this.leadApi.createFirestoreLead(body);
    };
    ApiWrapper.prototype.finishLead = function () {
        return this.leadApi.finishLead();
    };
    ApiWrapper.prototype.validateVoucher = function (body) {
        console.log('new voucher', body);
        return this.api.validateVoucher(body);
    };
    ApiWrapper.prototype.getOfferings = function (studioId) {
        console.log('get offerings', studioId);
        return this.api.getOfferings(studioId);
    };
    return ApiWrapper;
}());
exports.ApiWrapper = ApiWrapper;
