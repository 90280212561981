"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Index = void 0;
var PACKAGE = __importStar(require("../package.json"));
var object_service_1 = require("./object-service");
var checkout_1 = require("./section/checkout/checkout");
var event_listeners_1 = require("./section/finish/event-listeners");
var form_1 = require("./section/finish/form");
var price_preview_1 = require("./section/finish/price-preview");
var fitpos_1 = require("./section/fitpos/fitpos");
var lead_form_1 = require("./section/lead-form/lead-form");
var location_1 = require("./section/location/location");
var profile_edit_1 = require("./section/success/profile-edit");
var helper_const_1 = require("./services/helper.const");
var tracker_service_1 = require("./services/tracker.service");
require("./translation");
var variables_const_1 = require("./variables.const");
var chatgpt_1 = require("./widgets/chatgpt/chatgpt");
var Index = /** @class */ (function () {
    function Index() {
        var _a, _b;
        object_service_1.ObjectService.getInstance(tracker_service_1.TrackerService).loadModule();
        if (variables_const_1._settings && ((_a = variables_const_1._settings === null || variables_const_1._settings === void 0 ? void 0 : variables_const_1._settings.tenantKey) === null || _a === void 0 ? void 0 : _a.length)) {
            console.info("MAGICLINE SCRIPT ".concat(process.env.NODE_ENV.toUpperCase(), ":"), PACKAGE.version, variables_const_1.magicLineUrl);
            if (variables_const_1._settings.section === 'finish') {
                object_service_1.ObjectService.getInstance(event_listeners_1.EventListeners).addEvents();
                object_service_1.ObjectService.getInstance(form_1.Form).loadModule();
                object_service_1.ObjectService.getInstance(price_preview_1.PricePreview).initModule();
            }
            else if (variables_const_1._settings.section === 'checkout' ||
                variables_const_1._settings.section === 'modules') {
                object_service_1.ObjectService.getInstance(checkout_1.Checkout).loadModule();
            }
            else if (variables_const_1._settings.section === 'success') {
                object_service_1.ObjectService.getInstance(profile_edit_1.ProfileEdit).loadModule();
            }
            else if (variables_const_1._settings.section === 'location') {
                object_service_1.ObjectService.getInstance(location_1.Location).loadModule();
            }
            else if (variables_const_1._settings.section === 'leadform') {
                object_service_1.ObjectService.getInstance(lead_form_1.LeadForm).loadModule();
            }
            else if (variables_const_1._settings.section === 'fitpos') {
                object_service_1.ObjectService.getInstance(fitpos_1.Fitpos).loadModule();
            }
            else {
                console.error('NO SECTION IS DEFINED PLEASE SET SETTINGS.SECTION');
            }
            if ((_b = variables_const_1._settings.widgets) === null || _b === void 0 ? void 0 : _b.length) {
                if (variables_const_1._settings.widgets.includes('chatgpt')) {
                    object_service_1.ObjectService.getInstance(chatgpt_1.ChatGPT).loadModule();
                }
            }
            if (variables_const_1._settings === null || variables_const_1._settings === void 0 ? void 0 : variables_const_1._settings.hideDebugInfos) {
                console.error('NOTE YOU HAVE HIDDEN ANY DEV HINTS / LOGS ONLY RECOMMENDED FOR E.G. DEMOS');
            }
            if (variables_const_1.environment === 'dev') {
                console.info('Loading specific section: ', variables_const_1._settings.section, variables_const_1._settings);
                $('body').prepend('<h4 style="color:red; width:100%; background: #ddd; text-align:center; margin:0;padding:0;">USING DEV SCRIPT FOR TESTING PURPOSES ONLY</h4>');
                document.title = 'DEV SCRIPT: ' + document.title;
            }
            //Methods that are just called once per page
            (0, helper_const_1._CollapseAndUncollapse)();
            (0, helper_const_1._DataHide)();
        }
        else {
            console.error('Critical error: No Tenantname provided by webflow');
        }
    }
    return Index;
}());
exports.Index = Index;
document.addEventListener('DOMContentLoaded', function (event) {
    object_service_1.ObjectService.getInstance(Index);
});
