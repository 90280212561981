"use strict";
var _a, _b, _c, _d, _e;
Object.defineProperty(exports, "__esModule", { value: true });
exports.PRICEOVERVIEWID = exports.assetsUrl = exports.agileaCloudFunctionUrl = exports.athleticsUrl = exports.magicLineUrl = exports._legacyCode = exports._matured = exports._minimumAge = exports.environment = exports._AverageCustomerDuration = exports._settings = void 0;
function detectSection() {
    console.info('Note: automatically inferred finish section');
    return 'finish';
}
//Settings provided by this script
exports._settings = typeof settings !== "undefined" ? settings : undefined;
exports._AverageCustomerDuration = 2.5;
// Overwrites settings data if set / not set
if (exports._settings &&
    ((_a = localStorage.getItem('TenantId')) === null || _a === void 0 ? void 0 : _a.length) &&
    !settings.tenantKey) {
    exports._settings.tenantKey = localStorage.getItem('TenantId');
}
if (exports._settings && !((_b = settings === null || settings === void 0 ? void 0 : settings.section) === null || _b === void 0 ? void 0 : _b.length)) {
    exports._settings.section = 'finish';
}
exports.environment = process.env.NODE_ENV.toUpperCase() !== 'DEVELOPMENT' ||
    settings.hideDebugInfos
    ? 'prod'
    : 'dev';
exports._minimumAge = (_c = settings === null || settings === void 0 ? void 0 : settings.minimumAge) !== null && _c !== void 0 ? _c : 18; //if not set by webflow
exports._matured = 18; //if not set by webflow
exports._legacyCode = (_d = settings === null || settings === void 0 ? void 0 : settings.legacy) !== null && _d !== void 0 ? _d : false; // some old webflow code that has to be handled differently
exports.magicLineUrl = "https://".concat(exports._settings === null || exports._settings === void 0 ? void 0 : exports._settings.tenantKey, ".api.magicline.com");
exports.athleticsUrl = "https://files.scaleyourgym.com/files/webflow/new-magicline/dev-php/athletics-api.php";
exports.agileaCloudFunctionUrl = "https://europe-west3-named-catcher-373207.cloudfunctions.net/agilea-public-endpoint";
exports.assetsUrl = "https://files.scaleyourgym.com/files/webflow/new-magicline/".concat(exports.environment === 'dev' ? 'dev' : '', "/assets");
exports.PRICEOVERVIEWID = '#pricing-overview';
//additional debug infos for dev environment;
if (exports.environment === 'dev') {
    if ((settings === null || settings === void 0 ? void 0 : settings.minimumAge) > exports._matured) {
        console.error('Note Minimum age ' + exports._minimumAge + '  is > than matured age ' + exports._matured);
    }
    if (((_e = settings === null || settings === void 0 ? void 0 : settings.startDate) === null || _e === void 0 ? void 0 : _e.length) &&
        !(settings.startDate.includes('-') && typeof settings.startDate === 'string')) {
        console.error('You provided a incorrect startdate in the tenant settings. Either remove it or check the format "YYYY-MM-DD" e.g. "2023-06-05"');
    }
    if (exports._settings &&
        !$(exports.PRICEOVERVIEWID).length &&
        (exports._settings === null || exports._settings === void 0 ? void 0 : exports._settings.section) === 'finish') {
        console.error('Price Overview id is not set: ' + exports.PRICEOVERVIEWID);
    }
}
